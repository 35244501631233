import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { StyledRichText } from "../../vendor/hoa/rich_text"
import { SanityImage } from "../../vendor/hoa/sanity_image"
import { Heading } from "../Headings"

const StyledItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 230px;
  text-align: center;
  width: calc(50% - var(--item-row-column-gap));
`

const StyledImage = styled(SanityImage)`
  --item-row-dimension: min(170px, 100%);

  border-radius: 50%;
  display: block;
  height: var(--item-row-dimension);
  margin-bottom: 15px;
  object-fit: cover;
  width: var(--item-row-dimension);
`

const ItemTitle = styled.div`
  font-size: 1.0625rem;
  margin-bottom: 5px;
  text-transform: uppercase;
`

const ItemContent = styled(StyledRichText)`
  font-size: 0.875rem;
`

const Item = ({ content, image, title }) => (
  <StyledItem>
    <StyledImage alt={title || ""} image={image} />

    {title && <ItemTitle>{title}</ItemTitle>}
    {content && <ItemContent blocks={content} />}
  </StyledItem>
)

Item.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
}

const Styled = styled.div``

const ItemsContainer = styled.div`
  --item-row-column-gap: 20px;

  column-gap: var(--item-row-column-gap);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 15px; ;
`

const ItemRowPageComponent = ({ items, title }) => (
  <Styled>
    {title && <Heading>{title}</Heading>}

    <ItemsContainer>
      {items.map(item => (
        <Item key={item._key} {...item} />
      ))}
    </ItemsContainer>
  </Styled>
)

ItemRowPageComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

export default ItemRowPageComponent
