import clsx from "clsx"
import React from "react"
import PropTypes from "prop-types"
import { componentToReact } from "../../lib/pageComponentToReact"

const THEME = {
  clay: "bg-clay",
  stone: "bg-stone",
  card: "bg-card",
  onyx: "bg-onyx",
}

const getTheme = value => {
  return THEME[value] || ""
}

export const HorizontalContent = ({ content, contentBackground }) => {
  const { items } = content
  return (
    <section className="flex overflow-y-scroll px-6 md:pl-64 md:pr-8 pb-4">
      {items.map(item => {
        const Component = componentToReact(item)
        return (
          <Component
            key={item._key}
            {...item}
            className={clsx(
              "mr-8 last:mr-0 shrink-0",
              getTheme(contentBackground)
            )}
          />
        )
      })}
    </section>
  )
}

HorizontalContent.propTypes = {
  content: PropTypes.object,
  contentBackground: PropTypes.string,
}
