import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import { componentToReact } from "../../lib/pageComponentToReact"

const ORDER_MOBILE = {
  1: "order-1",
  2: "order-2",
  3: "order-3",
  4: "order-4",
  5: "order-5",
  6: "order-6",
  7: "order-7",
  8: "order-8",
  9: "order-9",
  10: "order-10",
  11: "order-11",
  12: "order-12",
}

const ORDER_TABLET = {
  1: "md:order-1",
  2: "md:order-2",
  3: "md:order-3",
  4: "md:order-4",
  5: "md:order-5",
  6: "md:order-6",
  7: "md:order-7",
  8: "md:order-8",
  9: "md:order-9",
  10: "md:order-10",
  11: "md:order-11",
  12: "md:order-12",
}

const ORDER_DESKTOP = {
  1: "lg:order-1",
  2: "lg:order-2",
  3: "lg:order-3",
  4: "lg:order-4",
  5: "lg:order-5",
  6: "lg:order-6",
  7: "lg:order-7",
  8: "lg:order-8",
  9: "lg:order-9",
  10: "lg:order-10",
  11: "lg:order-11",
  12: "lg:order-12",
}

const getOrder = (value, view) => {
  if (view === "desktop") return ORDER_DESKTOP[value] || ""
  if (view === "tablet") return ORDER_TABLET[value] || ""
  return ORDER_MOBILE[value] || ""
}

export const GridCell = ({ className, content, order }) => {
  const { orderMobile, orderTablet, orderDesktop } = order || {}
  const cn = clsx(
    getOrder(orderMobile, "mobile"),
    getOrder(orderTablet, "tablet"),
    getOrder(orderDesktop, "desktop"),
    className
  )

  return (
    <div className={cn}>
      {content &&
        content.map(component => {
          const Component = componentToReact(component)
          return <Component key={component._key} {...component} />
        })}
    </div>
  )
}

GridCell.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array,
  order: PropTypes.object,
}
