import { isNil } from "lodash"

const formatCurrency = (number, opts = {}) => {
  if (isNil(number)) {
    return number
  }

  const formatOpts = {
    style: "currency",
    currency: "USD",
  }

  if (!opts.long) {
    formatOpts.minimumFractionDigits = 0
  }

  const result = new Intl.NumberFormat("en-US", formatOpts).format(number)

  if (!opts.long && result.includes(".")) {
    return formatCurrency(number, { long: true })
  } else {
    return result
  }
}

export default formatCurrency
