import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { RichText, StyledRichText } from "../../vendor/hoa/rich_text"
import { FullWidthSanityImage } from "../../vendor/hoa/sanity_image"
import { PHONE_MAX } from "../CSSVariables"

const oneColumnMax = "1019px"
const twoColumnMin = "1020px"

const imagePercent = 64
const contentPercent = 100 - imagePercent

const Styled = styled.div`
  display: grid;

  @media (max-width: ${oneColumnMax}) {
  }

  @media (min-width: ${twoColumnMin}) {
    grid-template-columns: ${({ desktopLayout }) =>
      desktopLayout === "imageOnRight"
        ? `${contentPercent}% ${imagePercent}%`
        : `${imagePercent}% ${contentPercent}%`};

    grid-template-areas: ${({ desktopLayout }) =>
      desktopLayout === "imageOnRight" ? `"content image"` : `"image content"`};
  }
`

const ContentContainer = styled.div`
  background: black;
  color: var(--color-light);
  display: flex;
  flex-direction: column;
  font-size: 1.0625rem;
  justify-content: center;

  @media (max-width: ${oneColumnMax}) {
    padding: 0 13px 25px;
  }

  @media (min-width: ${twoColumnMin}) {
    grid-area: content;
    padding: 15px 7%;
  }
`

const ImageContainer = styled.div`
  @media (min-width: ${twoColumnMin}) {
    grid-area: image;
  }
`

const StyledContent = styled(StyledRichText)`
  font-weight: var(--weight-thin);

  @media (max-width: ${oneColumnMax}) {
    margin: 25px 0 20px;
  }

  @media (min-width: ${twoColumnMin}) {
    margin-bottom: 20px;
  }
`

const StyledTitle = styled.div`
  color: var(--color-brand-secondary);
  line-height: 1.3;
  text-transform: uppercase;

  p {
    margin: 0;
  }
`

const ImageByTextPortraitPageComponent = ({
  content,
  desktopLayout,
  image,
  title,
}) => (
  <Styled desktopLayout={desktopLayout}>
    <ImageContainer>
      <FullWidthSanityImage
        image={image}
        sizes={`(max-width: ${PHONE_MAX}px) 100vw, ${imagePercent}vw`}
      />
    </ImageContainer>

    <ContentContainer>
      <StyledContent blocks={content} />
      {title && (
        <StyledTitle>
          <RichText blocks={title} />
        </StyledTitle>
      )}
    </ContentContainer>
  </Styled>
)

ImageByTextPortraitPageComponent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  desktopLayout: PropTypes.string,
  image: PropTypes.object.isRequired,
  title: PropTypes.arrayOf(PropTypes.object),
}

export default ImageByTextPortraitPageComponent
