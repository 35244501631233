import PropTypes from "prop-types"
import React from "react"

export const CartIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#a)"
      >
        <path d="M14.146 5.334H1.855a1.04 1.04 0 0 0-.507.12c-.159.082-.3.205-.411.359a1.414 1.414 0 0 0-.235.528 1.522 1.522 0 0 0-.011.592l1.28 7.333c.054.306.2.58.413.775.213.195.479.298.75.292h9.732c.272.006.538-.097.75-.292.213-.195.36-.469.414-.775l1.28-7.333a1.522 1.522 0 0 0-.011-.592 1.415 1.415 0 0 0-.235-.528 1.197 1.197 0 0 0-.411-.36 1.04 1.04 0 0 0-.507-.12ZM10 .667l2.667 4.666M3.334 5.333 6.001.667" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

CartIcon.propTypes = {
  className: PropTypes.string,
}
