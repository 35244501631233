import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import TopNav from "./nav/TopNav"
import Footer from "./Footer"
import CartContainer from "./cart/CartContainer"
import ModalContainer from "./modals/ModalContainer"
import CSSVariables from "./CSSVariables"
import CMSContext from "./context/CMSContext"
import KlaviyoBanner from "./KlaviyoBanner"
import StoreContext from "./context/StoreContext"
import Notification from "./Notification"
import { observer } from "mobx-react-lite"
import MobileNav from "./nav/MobileNav"
import { SocialLinks } from "./social/social-links"

const Layout = ({ children, product, title }) => {
  const {
    flashStore: { message },
  } = useContext(StoreContext)

  const { navigation, siteSettings } = useContext(CMSContext)

  title = [title, siteSettings.siteTitle].filter(i => i).join(" | ")

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <link rel="icon" href={siteSettings.favicon.url} />
      </Helmet>

      <CSSVariables>
        <TopNav product={product} />

        {message && <Notification>{message}</Notification>}

        {children}
        <KlaviyoBanner />
        <SocialLinks socialItems={navigation.socialItems} />
        <Footer />
        <CartContainer />
        <MobileNav />
        <ModalContainer />
      </CSSVariables>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  product: PropTypes.object,
  title: PropTypes.string,
}

export default observer(Layout)
