import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { productPathFromHandle } from "../../lib/urls"
import {
  cadenceDescription,
  filterVariantOptions,
} from "../../lib/shopify/cartUtil"
import CartItemPricing from "./CartItemPricing"
import { EventLink } from "../../vendor/hoa/links"
import { CloseButton } from "../../vendor/hoa/close_button"

const Styled = styled.div`
  display: grid;
  grid-template-columns: 97px 1fr auto;
  padding: 20px 0;

  @media (hover: hover) {
    .CartItem__quantityButtons {
      opacity: 0;
      transition: opacity 0.3s linear;
    }
  }

  &:hover {
    .CartItem__quantityButtons {
      opacity: 1;
    }
  }
`

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PriceColumnLayout = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ImageLink = styled.a`
  padding-right: 22px;

  > img {
    display: block;
  }
`

const ProductTitleLink = styled.a`
  display: block;
  font-size: 0.8125rem;
  font-weight: var(--weight-thin);
  line-height: 1.5;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const KeyValue = styled.div`
  align-items: center;
  color: var(--color-medium);
  display: flex;
  font-size: 0.625rem;
  font-weight: var(--weight-thin-thin);
  letter-spacing: 0.1em;

  :not(:last-of-type) {
    margin-bottom: 7px;
  }
`

const Quantity = styled.span`
  font-size: 1.1em;
  font-weight: var(--weight-normal);
`

const QuantityButtons = styled.div`
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20px;
`

const QuantityButtonLink = styled(EventLink)`
  align-items: center;
  background: none;
  border: 0.5px solid black;
  display: flex;
  font-size: 1rem;
  font-weight: var(--weight-normal);
  height: 18px;
  line-height: 0;
  justify-content: center;
  width: 18px;
  text-align: center;
`

const StyledCloseButton = styled(CloseButton)`
  width: 10px;
`

const CartItem = ({ item, onRemoveItem, onUpdateItem }) => (
  <Styled>
    <ImageLink href={productPathFromHandle(item.variant.product.handle)}>
      {/* TODO optimize image */}
      {item.variant.image && (
        <img src={item.variant.image.src} alt="Product image" />
      )}
    </ImageLink>

    <ContentLayout>
      <div>
        <ProductTitleLink
          href={productPathFromHandle(item.variant.product.handle)}
        >
          {item.title}
        </ProductTitleLink>

        {cadenceDescription(item) ? (
          <KeyValue>{cadenceDescription(item)}</KeyValue>
        ) : null}

        {filterVariantOptions(item.variant.selectedOptions).map(
          ({ name, value }) => (
            <KeyValue key={name}>
              {name}: {value}
            </KeyValue>
          )
        )}
      </div>

      <KeyValue>
        <div>
          Qty: <Quantity>{item.quantity}</Quantity>
        </div>

        <QuantityButtons className="CartItem__quantityButtons">
          <QuantityButtonLink
            onClick={() => {
              onUpdateItem(item, { quantity: item.quantity - 1 })
            }}
          >
            -
          </QuantityButtonLink>

          <QuantityButtonLink
            onClick={() => {
              onUpdateItem(item, { quantity: item.quantity + 1 })
            }}
          >
            +
          </QuantityButtonLink>
        </QuantityButtons>
      </KeyValue>
    </ContentLayout>

    <PriceColumnLayout>
      <StyledCloseButton
        alt="remove item from cart"
        onClick={() => {
          onRemoveItem(item)
        }}
      />
      <CartItemPricing item={item} />
    </PriceColumnLayout>
  </Styled>
)

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
}

export default CartItem
