import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { RichText } from "../../vendor/hoa/rich_text"

const Styled = styled.div`
  font-size: 1.0625rem;
  line-height: 1.5;
  text-align: center;

  p,
  ul {
    margin: 14px 0;
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: var(--weight-slight-bold);
  }

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding: 5px 6px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    margin: 0 auto;
    max-width: 900px;
    padding: 25px 0;
    width: 90%;
  }
`

const FeaturedTextPageComponent = ({ content }) => (
  <Styled>
    <RichText blocks={content} />
  </Styled>
)

FeaturedTextPageComponent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default FeaturedTextPageComponent
