import PropTypes from "prop-types"
import React from "react"

export const ArrowRight = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3.667.5 7.147 7.147a.5.5 0 0 1 0 .706L3.667 15.5"
    />
  </svg>
)

ArrowRight.propTypes = {
  className: PropTypes.string,
}
