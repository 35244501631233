import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { StyledRichText } from "../../vendor/hoa/rich_text"
import { SanityImage } from "../../vendor/hoa/sanity_image"
import { Heading } from "../Headings"

const imageDimension = "170px"

const StyledItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const ItemImage = styled(SanityImage)`
  border-radius: 50%;
  display: block;
  height: ${imageDimension};
  margin-bottom: 18px;
  object-fit: cover;
  width: ${imageDimension};
`

const ItemTitle = styled.div`
  font-size: 1.0625rem;
  text-transform: uppercase;
`

const ItemSubtitle = styled.div`
  color: var(--color-brand-secondary);
  font-size: 0.875rem;
  font-style: italic;
  margin-top: 10px;
  text-transform: uppercase;
`

const RichTextContainer = styled.div`
  margin-top: 15px;
`

const BioItem = ({ description, image, subtitle, title }) => (
  <StyledItem>
    <ItemImage alt={title || ""} image={image} sizes={imageDimension} />

    <ItemTitle>{title}</ItemTitle>
    {subtitle && <ItemSubtitle>{subtitle}</ItemSubtitle>}

    <RichTextContainer>
      <StyledRichText blocks={description} />
    </RichTextContainer>
  </StyledItem>
)

BioItem.propTypes = {
  description: PropTypes.arrayOf(PropTypes.object).isRequired,
  image: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

const Styled = styled.div`
  padding: 15px 0 8px;
`

const ItemsContainer = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: ${props => props.theme.tabletMin}) {
    grid-template-columns: repeat(2, minmax(auto, 512px));
  }
`

const BioPageComponent = ({ items, title }) => (
  <Styled>
    {title && <Heading>{title}</Heading>}

    <ItemsContainer>
      {items.map(item => (
        <BioItem key={item._key} {...item} />
      ))}
    </ItemsContainer>
  </Styled>
)

BioPageComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

export default BioPageComponent
