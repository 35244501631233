import PropTypes from "prop-types"
import React from "react"
import { StyledRichText } from "../../vendor/hoa/rich_text"

const RichTextPageComponent = ({ content }) => {
  return <StyledRichText blocks={content} />
}

RichTextPageComponent.propTypes = {
  content: PropTypes.array.isRequired,
}

export default RichTextPageComponent
