import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"
import CMSContext from "../context/CMSContext"
import CartPadding from "./CartPadding"

const Styled = styled.div`
  @media (max-width: ${props => props.theme.phoneMax}) {
    display: none;
  }
`

const FreeShippingText = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  height: 50px;
`

const ProgressBar = styled.div`
  background: rgb(232, 231, 229);
  height: 8px;
  position: relative;

  ::after {
    background: var(--background-dark);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    width: ${({ fraction }) => 100 * fraction}%;
    top: 0;
  }
`

const FreeShippingProgress = ({ checkout }) => {
  const {
    siteSettings: { freeShippingLimit },
  } = useContext(CMSContext)

  const subtotal = Number(checkout.lineItemsSubtotalPrice.amount)
  const freeShippingFraction = Math.min(1, subtotal / freeShippingLimit)

  return (
    <Styled>
      {subtotal >= freeShippingLimit ? (
        <CartPadding>
          <FreeShippingText>
            <span>
              You’re Eligible for <strong>Free Shipping</strong>!
            </span>
          </FreeShippingText>
        </CartPadding>
      ) : (
        <CartPadding>
          <FreeShippingText>
            <span>
              You're{" "}
              <strong>{formatCurrency(freeShippingLimit - subtotal)}</strong>{" "}
              away from Free Shipping!
            </span>
          </FreeShippingText>
        </CartPadding>
      )}

      <ProgressBar fraction={freeShippingFraction} />
    </Styled>
  )
}

FreeShippingProgress.propTypes = {
  checkout: PropTypes.object.isRequired,
}

export default FreeShippingProgress
