import React from "react"
import { Link } from "gatsby"

const temporarilyDisableScroll = () => {
  // This is an ugly hack but it works well and is the best solution that I (Brian) could find.
  window.__hoaGatsbyScrollEnabled = false

  setTimeout(() => {
    window.__hoaGatsbyScrollEnabled = true
  }, 1000)
}

const PreserveScrollLink = props => (
  <Link {...props} onClick={temporarilyDisableScroll} />
)

PreserveScrollLink.propTypes = {}

export default PreserveScrollLink
