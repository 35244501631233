import React, { useContext } from "react"
import { observer } from "mobx-react-lite"

import StoreContext from "../context/StoreContext"
import { CartIcon } from "../../icons"
import { TopNavButton } from "./top-nav-button"

const CartButton = () => {
  const { overlayStore, checkoutStore } = useContext(StoreContext)
  const lineItemCount = checkoutStore.itemCount

  const onClick = evt => {
    evt.preventDefault()
    overlayStore.showCart()
  }

  return (
    <TopNavButton onClick={onClick}>
      <CartIcon className="w-4 h-4 color-white" />
      {lineItemCount > 0 && (
        <div className="flex items-center justify-center absolute top-5 left-5 bg-clay w-3 h-3 rounded-full text-[8px] text-onyx">
          {lineItemCount}
        </div>
      )}
    </TopNavButton>
  )
}

export default observer(CartButton)
