import PropTypes from "prop-types"
import React from "react"

export const MindBody = ({ id }) => (
  <healcode-widget
    data-type="schedules"
    data-widget-partner="object"
    data-widget-id={id}
    data-widget-version="1"
  />
)

MindBody.propTypes = {
  id: PropTypes.string.isRequired,
}
