import PropTypes from "prop-types"
import React from "react"

export const ArrowLeft = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.833 15.5 3.687 8.353a.5.5 0 0 1 0-.706L10.833.5"
    />
  </svg>
)

ArrowLeft.propTypes = {
  className: PropTypes.string,
}
