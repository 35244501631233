import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import { componentToReact } from "../../lib/pageComponentToReact"

// tailwind doesn't want to understand classes which was generated on the fly :(
// `lg:grid-cols-${numberOfCellsDesktop}` - is not working
const MOBILE_CELLS = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
}

const TABLET_CELLS = {
  1: "md:grid-cols-1",
  2: "md:grid-cols-2",
  3: "md:grid-cols-3",
  4: "md:grid-cols-4",
  5: "md:grid-cols-5",
  6: "md:grid-cols-6",
}

const DESKTOP_CELLS = {
  1: "lg:grid-cols-1",
  2: "lg:grid-cols-2",
  3: "lg:grid-cols-3",
  4: "lg:grid-cols-4",
  5: "lg:grid-cols-5",
  6: "lg:grid-cols-6",
  7: "lg:grid-cols-7",
  8: "lg:grid-cols-8",
  9: "lg:grid-cols-9",
  10: "lg:grid-cols-10",
  11: "lg:grid-cols-11",
  12: "lg:grid-cols-12",
}

const JUSTIFY_ITEMS = {
  start: "justify-items-start",
  center: "justify-items-center",
  end: "justify-items-start",
}

const ALIGN_ITEMS = {
  start: "items-start",
  center: "items-center",
  end: "items-end",
}

const PRESETS = {
  appPromo: "grid-cols-1 sm:grid-cols[1fr,_480px,_1fr]",
}

const getCellsAlign = (value, direction) => {
  if (direction === "vertical") return ALIGN_ITEMS[value] || ""
  return JUSTIFY_ITEMS[value] || ""
}

const getCellClass = (cells, viewport) => {
  if (viewport === "desktop") return DESKTOP_CELLS[cells] || ""
  if (viewport === "tablet") return TABLET_CELLS[cells] || ""

  return MOBILE_CELLS[cells] || ""
}

export const Grid = ({
  className,
  content,
  numberOfCellsMobile,
  numberOfCellsTablet,
  numberOfCellsDesktop,
  verticalAlign,
  horizontalAlign,
  presets,
}) => {
  const gridClassName = clsx(
    "grid w-full",
    getCellClass(numberOfCellsMobile, "mobile"),
    getCellClass(numberOfCellsTablet, "tablet"),
    getCellClass(numberOfCellsDesktop, "desktop"),
    getCellsAlign(verticalAlign, "vertical"),
    getCellsAlign(horizontalAlign, "horizontal"),
    PRESETS[presets] || "",
    className
  )

  return (
    <div className={gridClassName}>
      {content.map(component => {
        const Component = componentToReact(component)
        return <Component key={component._key} {...component} />
      })}
    </div>
  )
}

Grid.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array,
  numberOfCellsMobile: PropTypes.number,
  numberOfCellsTablet: PropTypes.number,
  numberOfCellsDesktop: PropTypes.number,
  horizontalAlign: PropTypes.string,
  verticalAlign: PropTypes.string,
  presets: PropTypes.string,
}
