import React from "react"
import styled from "@emotion/styled"
import loadingImg from "../images/hoa-loader.svg"

const Styled = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 100px;
`

const Spinner = () => (
  <Styled>
    <img src={loadingImg} alt="Loading, please wait." />
  </Styled>
)

Spinner.propTypes = {}

export default Spinner
