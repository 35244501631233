import React, { useEffect } from "react"
import styled from "@emotion/styled"
import loadScript from "../../lib/loadScript"

const Styled = styled.div`
  min-height: 400px;
  min-width: 320px;
`

const widgetHtml = `
<healcode-widget
  data-type="prospects"
  data-widget-partner="object"
  data-widget-id="16358475541"
  data-widget-version="0"
/>
`

const MindBodyModal = () => {
  useEffect(() => {
    loadScript("https://widgets.mindbodyonline.com/javascripts/healcode.js")
  }, [])

  // using dangerouslySetInnerHTML for https://github.com/house-of-athlete/hoa_gatsby/issues/72
  return <Styled dangerouslySetInnerHTML={{ __html: widgetHtml }} />
}

MindBodyModal.propTypes = {}

MindBodyModal.padding = "0"

export default MindBodyModal
