/** @jsx jsx */
import PropTypes from "prop-types"
import { css, Global, jsx, ThemeProvider } from "@emotion/react"

export const PHONE_MAX = 767
export const TABLET_MIN = 768 // Tailwind "md"
export const TABLET_MAX = 1023
export const DESKTOP_MIN = 1024 // Tailwind "lg"

export const MOBILE_NAV_MAX = TABLET_MAX
export const DESKTOP_NAV_MIN = DESKTOP_MIN

const globalCss = css`
  :root {
    --onyx: #000;
    --clay-light: #e3dfdc;
    --clay: #8c7e70;

    --background-dark: #3d3935;
    --background-drawer: rgb(244, 244, 244);
    --background-light-gray: #f5f5f5;

    --color-brand-secondary: #b2a67b;
    --color-error: #dc3545;
    --color-light: #fff;
    --color-gray: #e8e7e5;
    --color-medium-gray: #999;
    --color-dark-gray: #616060;
    --color-medium: #3d3935;
    --color-default-text: #252525;
    --color-disabled: #a7a6a6;
    --color-shop-pay: #5a31f4;
    --color-success: #0f5132;

    /* These are the supported font weights */
    --weight-thin-thin: 100;
    --weight-thin: 300;
    --weight-normal: 400;
    --weight-slight-bold: 550;
    --weight-bold: 700;
    --weight-bold-bold: 900;

    --clickable-area-padding: 6px;
    --extra-margin: 35px;
    --notification-banner-height: 32px;

    --hoa-grid-column-gap: var(--grid-gap);
    --hoa-grid-overlay-font-weight: var(--weight-slight-bold);
    --hoa-grid-row-gap: var(--grid-gap);

    --hoa-mobile-nav-background: var(--background-drawer);
    --hoa-mobile-nav-border: 1px solid var(--color-gray);
    --hoa-mobile-nav-item-height: 73px;
    --hoa-mobile-nav-side-padding: 20px;

    --hoa-modal-background: #252525;

    --hoa-nav-button-color: var(--color-medium);

    --viewport-height-excluding-header: calc(
      100vh - var(--notification-banner-height) - var(--top-nav-height)
    );
  }

  @media (min-width: ${TABLET_MIN}px) {
    :root {
      --default-margin: 20px;
      --grid-gap: 10px;
      --top-nav-height: 60px;
    }
  }

  @media (max-width: ${PHONE_MAX}px) {
    :root {
      --default-margin: 10px;
      --grid-gap: 5px;
      --top-nav-height: 54px;
    }
  }
`

// See https://emotion.sh/docs/theming
const theme = {
  phoneMax: `${PHONE_MAX}px`,
  tabletMin: `${TABLET_MIN}px`,
  tabletMax: `${TABLET_MAX}px`,
  desktopMin: `${DESKTOP_MIN}px`,
}

const CSSVariables = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Global styles={globalCss} />
    {children}
  </ThemeProvider>
)

CSSVariables.propTypes = {
  children: PropTypes.node,
}

export default CSSVariables
