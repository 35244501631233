import React, { useState } from "react"
import styled from "@emotion/styled"
import Button from "./Button"
import Container from "./Container"
import { subscribeToMailingList } from "../lib/klaviyo"

const KlaviyoBannerContainer = styled.div`
  border-radius: 8px;
  column-gap: 25px;
  display: grid;
  row-gap: 20px;

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding: 25px 15px 20px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    align-items: center;
    grid-template-columns: repeat(2, minmax(auto, 450px));
    justify-content: center;
    padding: 35px var(--default-margin);
  }
`

const Content = styled.div`
  line-height: 1.7;

  @media (min-width: ${props => props.theme.tabletMin}) {
    text-align: right;
  }
`

const Title = styled.div`
  font-size: 1rem;
  font-weight: var(--weight-slight-bold);
  text-transform: uppercase;
`

const Input = styled.input`
  background-color: var(--onyx);
  border-radius: 8px;
  border: 1px solid;
  width: 100%;

  &:focus {
    border: 1px solid transparent;
  }

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding: 15px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    padding: 20px;
  }
`

const StyledButton = styled(Button)`
  margin-top: 15px;

  @media (min-width: ${props => props.theme.tabletMin}) {
    display: none;
  }
`

const Message = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`

const ErrorMessage = styled(Message)`
  color: var(--color-error);
`

const SuccessMessage = styled(Message)`
  color: var(--color-success);
`

const KlaviyoBanner = () => {
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("ready") // "submitting", "success", or "failure"

  const submit = async () => {
    if (status === "submitting") {
      return
    }

    setStatus("submitting")

    try {
      await subscribeToMailingList({
        email,
        listId: process.env.GATSBY_KLAVIYO_LIST_ID,
      })
      setStatus("success")
    } catch (error) {
      console.error(error)
      setStatus("failure")
    }
  }

  return (
    <Container>
      <KlaviyoBannerContainer>
        <Content>
          <Title>Join the tribe</Title>
          Sign up for exclusive offers, original stories, athlete.
        </Content>

        <form
          onSubmit={event => {
            event.preventDefault()
            submit()
          }}
        >
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            required
            onChange={event => {
              setEmail(event.target.value)
            }}
          />

          <StyledButton type="submit">Subscribe</StyledButton>
        </form>

        {status === "success" && (
          <SuccessMessage>Thank you - you're signed up!</SuccessMessage>
        )}

        {status === "failure" && (
          <ErrorMessage>
            An error occurred. Please check that the email address is correct
            and try again.
          </ErrorMessage>
        )}
      </KlaviyoBannerContainer>
    </Container>
  )
}

KlaviyoBanner.propTypes = {}

export default KlaviyoBanner
