import PropTypes from "prop-types"
import React from "react"
import SubNav from "../nav/SubNav"

const SubNavPageComponent = ({ subNav }) => (
  <SubNav
    heading={subNav.title}
    items={subNav.items.map(({ document, title }) => ({
      title,
      to: document.path.current,
    }))}
  />
)

SubNavPageComponent.propTypes = {
  subNav: PropTypes.object.isRequired,
}

export default SubNavPageComponent
