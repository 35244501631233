import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import { SanityImage } from "../../vendor/hoa/sanity_image"

export const Testimonial = ({ link, logo, title, className }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(
        className,
        "py-6 md:py-8 px-4 md:px-6 w-64 md:w-100 min-h-40 md:min-h-56 rounded-lg flex flex-col items-center shrink-0"
      )}
    >
      <SanityImage image={logo} className="w-auto h-6 md:h-12 mb-6 md:mb-8" />
      <p className="text-sm md:text-lg font-light text-center">{title}</p>
    </a>
  )
}

Testimonial.propTypes = {
  link: PropTypes.string,
  logo: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
}
