import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { EventLink } from "../../vendor/hoa/links"
import { StyledRichText } from "../../vendor/hoa/rich_text"
import { Heading } from "../Headings"
import { ChevronDown, ChevronUp } from "../../vendor/hoa/icons"

const StyledItem = styled.div`
  --faq-item-border: 1px solid var(--color-gray);

  border-top: var(--faq-item-border);
  padding: 24px 0 22px;

  &:last-of-type {
    border-bottom: var(--faq-item-border);
  }
`

const Answer = styled.div`
  margin-top: 20px;
`

const Question = styled(EventLink)`
  align-items: center;
  display: flex;
  line-height: 1.3;
  font-size: 1.1rem;
  font-weight: var(--weight-bold);
  justify-content: space-between;
`

const StyledChevron = styled.div`
  --hoa-nav-button-color: currentColor;
  width: 18px;
`

const FAQItem = ({ answer, question }) => {
  const [isExpanded, setExpanded] = useState(false)

  return (
    <StyledItem>
      <Question
        onClick={() => {
          setExpanded(!isExpanded)
        }}
      >
        {question}

        <StyledChevron as={isExpanded ? ChevronUp : ChevronDown} />
      </Question>

      {isExpanded && (
        <Answer>
          <StyledRichText blocks={answer} />
        </Answer>
      )}
    </StyledItem>
  )
}

FAQItem.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.object).isRequired,
  question: PropTypes.string.isRequired,
}

const Styled = styled.div``

const ItemsContainer = styled.div`
  margin: 20px auto 0;
  max-width: 890px;
  padding: 0 10px;
`

const FAQPageComponent = ({ items, title }) => (
  <Styled>
    <Heading>{title}</Heading>

    <ItemsContainer>
      {items.map(item => (
        <FAQItem key={item._key} {...item} />
      ))}
    </ItemsContainer>
  </Styled>
)

FAQPageComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default FAQPageComponent
