import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import { EventLink } from "../vendor/hoa/links"
import StoreContext from "./context/StoreContext"

const Styled = styled.div`
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  color: #084298;
  margin: 0.625rem auto 1.25rem;
  max-width: 600px;
  padding: 0.625rem 0.625rem;
  position: relative;
  width: 80%;
`

const CloseButton = styled(EventLink)`
  font-size: 1.25rem;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`

const Notification = ({ children }) => {
  const { flashStore } = useContext(StoreContext)

  return (
    <Styled>
      {children}
      <CloseButton
        onClick={() => {
          flashStore.clear()
        }}
      >
        &times;
      </CloseButton>
    </Styled>
  )
}

Notification.propTypes = { children: PropTypes.node }

export default Notification
