import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"
import shopPayWithAffirmLogo from "../../images/shop-pay-with-affirm-black.svg"

const StyledItem = styled.div`
  margin: 0 0 15px 20px;
`

const ItemTitle = styled.div`
  font-size: 1.125rem;
  font-weight: var(--weight-slight-bold);
  margin-bottom: 5px;
`
const ItemSubtitle = styled.div`
  color: var(--color-medium-gray);
`

const Item = ({ title, subtitle }) => (
  <StyledItem>
    <ItemTitle>{title}</ItemTitle>
    <ItemSubtitle>{subtitle}</ItemSubtitle>
  </StyledItem>
)

Item.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 80vw;
`

const Heading = styled.div`
  font-size: 1.875rem;
  font-weight: var(--weight-bold);
  line-height: 1.4;
  margin-bottom: 20px;
`

const Description = styled.div`
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 20px;

  strong {
    color: var(--color-shop-pay);
  }
`

const CloseButton = styled.button`
  appearance: none;
  background: #e9e6fa;
  border: none;
  border-radius: 4px;
  color: var(--color-shop-pay);
  font-size: 1rem;
  font-weight: bold;
  margin-top: 5px;
  padding: 17px 0;
`

const LogoImg = styled.img`
  align-self: center;
  width: 250px;
`

const Terms = styled.div`
  color: var(--color-medium-gray);
  margin: 15px 0 20px;
  text-align: center;
`

const ShopPayInstallmentsModal = ({ onClose, variant }) => (
  <Styled>
    <Heading>
      Pay now or later -<br /> It's up to you.
    </Heading>

    <Description>
      Choose Shop Pay at checkout to pay in full or split your purchase into 4
      equal installments of <strong>{formatCurrency(variant.price / 4)}</strong>
      .
    </Description>

    <Item
      title="No hidden fees"
      subtitle="No interest, no fees, and no impact to your credit score."
    />

    <Item
      title="Make the most of your budget"
      subtitle="Automatic payments every two weeks on your debit card keep things flexible."
    />

    <CloseButton onClick={onClose}>Close</CloseButton>

    <Terms>
      *Subject to eligibility check and approval. Estimated payment amount
      excluded taxes and shipping fees.
    </Terms>

    <LogoImg src={shopPayWithAffirmLogo} alt="Shop Pay Logo" />
  </Styled>
)

ShopPayInstallmentsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.object.isRequired,
}

export default ShopPayInstallmentsModal
