import React, { useContext } from "react"
import { observer } from "mobx-react-lite"

import StoreContext from "../context/StoreContext"
import { MenuIcon } from "../../icons/index"
import { TopNavButton } from "./top-nav-button"

const MenuButton = () => {
  const { overlayStore } = useContext(StoreContext)
  const onClick = evt => {
    evt.preventDefault()
    overlayStore.showMobileNav()
  }

  return (
    <TopNavButton onClick={onClick} className="lg:hidden">
      <MenuIcon className="w-4 h-4 color-white" />
    </TopNavButton>
  )
}

export default observer(MenuButton)
