import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const Styled = styled.div`
  padding: 0 22px;
`

const CartPadding = ({ children }) => <Styled>{children}</Styled>

CartPadding.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CartPadding
