import CartPadding from "./CartPadding"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react-lite"
import StoreContext from "../context/StoreContext"
import { EventLink } from "../../vendor/hoa/links"
import { ChevronRight } from "../../vendor/hoa/icons"

const CartHeaderLayout = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  font-weight: var(--weight-slight-bold);
  height: 59px;
  justify-content: space-between;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

const Styled = styled.div`
  border-bottom: 1px solid lightgrey;
`

const CloseLink = styled(EventLink)`
  padding: var(--clickable-area-padding);
  position: relative;
  left: var(--clickable-area-padding);
`

const CartHeader = () => {
  const { overlayStore } = useContext(StoreContext)

  return (
    <Styled>
      <CartPadding>
        <CartHeaderLayout>
          Your shopping cart
          <CloseLink
            onClick={() => {
              overlayStore.hide()
            }}
          >
            <ChevronRight />
          </CloseLink>
        </CartHeaderLayout>
      </CartPadding>
    </Styled>
  )
}

CartHeader.propTypes = {}

export default observer(CartHeader)
