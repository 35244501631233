import PropTypes from "prop-types"
import React from "react"
import { componentToReact } from "../../lib/pageComponentToReact"

export const Rear = ({ content }) => {
  if (!content) return null

  return (
    <div className="absolute inset-0 bg-onyx opacity-0 hover:opacity-80 px-4 py-4 transition-opacity duration-300">
      {content.map(component => {
        const Component = componentToReact(component)
        return <Component key={component._key} {...component} />
      })}
    </div>
  )
}

Rear.propTypes = {
  content: PropTypes.array,
}
