import { InlineWidget } from "react-calendly"
import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const CalendlyWrapper = styled.section({
  "& > div": {
    height: "690px !important",
  },

  "@media screen and (max-width: 999px)": {
    "& > div": {
      height: "1500px !important",
    },
  },

  "@media screen and (max-width: 649px)": {
    "& > div": {
      height: "1200px !important",
    },
  },
})

const Calendly = ({ calendlyUrl }) => (
  <CalendlyWrapper>
    <InlineWidget url={calendlyUrl} />
  </CalendlyWrapper>
)

Calendly.propTypes = {
  calendlyUrl: PropTypes.string.isRequired,
}

export default Calendly
