import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

const Styled = styled.div`
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`

const VerticalScrollContainer = ({ children }) => <Styled>{children}</Styled>

VerticalScrollContainer.propTypes = {
  children: PropTypes.node,
}

export default VerticalScrollContainer
