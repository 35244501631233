import PropTypes from "prop-types"
import React, { Suspense, useState } from "react"
import styled from "@emotion/styled"
import { Heading } from "../Headings"
import SubtleLoadingIndicator from "../SubtleLoadingIndicator"
import { signUpForBackInStock, subscribeToMailingList } from "../../lib/klaviyo"

const BackInStockForm = React.lazy(async () => import("./BackInStockForm"))

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 25px;
  width: min(600px, 95vw);
`

const SuccessMessage = styled.div`
  color: var(--color-brand-secondary);
  font-weight: var(--weight-bold);
  text-align: center;
`

const BackInStockModal = ({ product, variant }) => {
  const [isSuccess, setSuccess] = useState(false)

  const submit = async ({ email }, { setFieldError, setSubmitting }) => {
    try {
      if (product.customInventoryKlaviyoListId) {
        await subscribeToMailingList({
          email,
          listId: product.customInventoryKlaviyoListId,
        })
      } else {
        await signUpForBackInStock({
          accountId: process.env.GATSBY_KLAVIYO_ACCOUNT_ID,
          email,
          variant,
        })
      }

      setSuccess(true)
    } catch (error) {
      console.error(error)
      setFieldError("email", "An error occurred. Please try again.")
    }

    setSubmitting(false)
  }

  return (
    <Styled>
      <Heading>{product.title}</Heading>

      {isSuccess ? (
        <SuccessMessage>
          Thank you. We'll email you when this product is available.
        </SuccessMessage>
      ) : (
        <Suspense fallback={<SubtleLoadingIndicator />}>
          <BackInStockForm onSubmit={submit} />
        </Suspense>
      )}
    </Styled>
  )
}

BackInStockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
}

export default BackInStockModal
