import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { CSSTransition } from "react-transition-group"
import Cart from "./Cart"
import StoreContext from "../context/StoreContext"
import { ModalBackground } from "../../vendor/hoa/modal"
import { PreventScrolling } from "../../vendor/hoa/prevent_scrolling"

const timeout = 400

const contentFadeKeyframes = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`

const ContentFadeContainer = styled.div`
  animation: ${contentFadeKeyframes} ${(2 * timeout) / 1000}s;
`

const FixedCart = styled.div`
  /* extending background covers glitchy scroll behavior on Android phones */
  --overflow: 100px;

  background: var(--background-drawer);
  color: var(--onyx);
  bottom: calc(-1 * var(--overflow));
  padding: var(--overflow) 0;
  position: fixed;
  right: 0;
  top: calc(-1 * var(--overflow));
  transition: transform ${timeout / 1000}s ease-in-out;
  will-change: transform;
  z-index: 101;

  @media (max-width: 479px) {
    width: 100%;
  }

  @media (min-width: 480px) {
    width: 400px;
  }
`

const Styled = styled.div`
  .cart-transition-drawer-enter {
    transform: translateX(100%);
  }

  .cart-transition-drawer-enter-active {
    transform: translateX(0);
  }

  .cart-transition-drawer-exit {
    transform: translateX(0);
  }

  .cart-transition-drawer-exit-active {
    transform: translateX(100%);
  }
`

const CartContainer = () => {
  const { overlayStore } = useContext(StoreContext)
  const { isCartExpanded } = overlayStore

  const hideCart = () => {
    overlayStore.hide()
  }

  return (
    <Styled>
      {isCartExpanded && <PreventScrolling />}

      <ModalBackground
        isExpanded={isCartExpanded}
        onClick={hideCart}
        timeout={timeout}
      />

      <CSSTransition
        classNames="cart-transition-drawer"
        in={isCartExpanded}
        mountOnEnter
        timeout={timeout}
        unmountOnExit
      >
        <FixedCart>
          <ContentFadeContainer>
            <Cart />
          </ContentFadeContainer>
        </FixedCart>
      </CSSTransition>
    </Styled>
  )
}

CartContainer.propTypes = {}

export default observer(CartContainer)
