import React from "react"
import PropTypes from "prop-types"
import { Widget } from "@typeform/embed-react"

export const Typeform = ({ id, height = "100%" }) => (
  <Widget id={id} style={{ height }} />
)

Typeform.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.string,
}
