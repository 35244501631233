import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"
import CartPadding from "./CartPadding"

const Styled = styled.div`
  border-top: 1px solid black;
  padding: 22px 0;
`

const TwoColumn = styled.div`
  display: flex;
  justify-content: space-between;
`

const CartTotals = ({ subtotal }) => (
  <Styled>
    <CartPadding>
      <TwoColumn>
        <div>Subtotal</div>
        <div>{formatCurrency(subtotal)}</div>
      </TwoColumn>
    </CartPadding>
  </Styled>
)

CartTotals.propTypes = {
  subtotal: PropTypes.number.isRequired,
}

export default CartTotals
