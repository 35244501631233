import PropTypes from "prop-types"
import React from "react"

export const SocialLinks = ({ socialItems = [] }) => {
  return (
    <section className="flex justify-center mt-10">
      <div className="grid gap-x-4 grid-cols-4">
        {socialItems.map(({ _key, socialNetwork: { icon, title }, url }) => (
          <a
            key={_key}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex w-10 h-10 bg-white rounded-full justify-center items-center"
          >
            <img src={icon.url} alt={title} className="block w-4 h-16" />
          </a>
        ))}
      </div>
    </section>
  )
}

SocialLinks.propTypes = {
  socialItems: PropTypes.arrayOf(PropTypes.object),
}
