import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

const delayedFadeIn = keyframes`
  0%, 80% { opacity: 0 }
  100% { opacity: 1 };
`

const Styled = styled.div`
  animation: ${delayedFadeIn} 1.5s linear;
  padding: 20px 0;
  text-align: center;
`

const SubtleLoadingIndicator = () => <Styled>Loading...</Styled>

SubtleLoadingIndicator.propTypes = {}

export default SubtleLoadingIndicator
