import CartPadding from "./CartPadding"
import PropTypes from "prop-types"
import React from "react"
import CartItem from "./CartItem"

const CartItems = ({ checkout, onRemoveItem, onUpdateItem }) => (
  <CartPadding>
    {checkout.lineItems.map(item => (
      <CartItem
        key={item.id}
        item={item}
        onRemoveItem={onRemoveItem}
        onUpdateItem={onUpdateItem}
      />
    ))}
  </CartPadding>
)

CartItems.propTypes = {
  checkout: PropTypes.object.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
}

export default CartItems
