import PropTypes from "prop-types"
import React from "react"
import { SubNavContainer, SubNavItems, SubNavHeading } from "../nav/SubNav"

const AnchorNavPageComponent = ({ items, title }) => (
  <SubNavContainer>
    <SubNavHeading>{title}</SubNavHeading>

    <SubNavItems>
      {items.map(item => (
        <a
          key={item._key}
          href={`#${item.anchor}`}
          onClick={event => {
            event.preventDefault()
            const elem = document.getElementById(item.anchor)

            if (elem) {
              elem.scrollIntoView({ behavior: "smooth" })
            }
          }}
        >
          {item.title}
        </a>
      ))}
    </SubNavItems>
  </SubNavContainer>
)

AnchorNavPageComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default AnchorNavPageComponent
