import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { Link } from "gatsby"
import { EventLink } from "../vendor/hoa/links"

const Styled = styled("a", { shouldForwardProp: isPropValid })`
  appearance: none;
  background: var(--button-background);
  border: none;
  cursor: pointer;
  font-size: 0.9375rem;
  letter-spacing: 0.033333333em;
  line-height: 1;
  padding: 16px 40px;
  text-align: center;
  text-transform: uppercase;

  display: ${({ $isInline }) => ($isInline ? "inline-block" : "block")};
  width: ${({ $isInline }) => ($isInline ? "auto" : "100%")};

  &,
  &:visited,
  &:hover,
  &:active {
    color: var(--button-color);
  }
`

const DefaultTheme = styled(Styled)`
  --button-background: var(--background-dark);
  --button-color: var(--color-light);
`

const DarkOnLightTheme = styled(Styled)`
  --button-background: var(--color-light);
  --button-color: var(--background-dark);
`

const DarkOnLightWithBorderTheme = styled(DarkOnLightTheme)`
  border: 1px solid var(--color-default-text);
`

const getThemedComponent = theme => {
  switch (theme) {
    case "darkOnLight":
      return DarkOnLightTheme

    case "darkOnLightWithBorder":
      return DarkOnLightWithBorderTheme

    default:
      return DefaultTheme
  }
}

const Button = ({ children, theme, ...props }) => {
  const Theme = getThemedComponent(theme)

  return (
    <Theme as="button" {...props}>
      {children}
    </Theme>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
}

export default Button

export const EventLinkButton = ({ children, isInline, onClick, theme }) => {
  const Theme = getThemedComponent(theme)

  return (
    <Theme as={EventLink} $isInline={isInline} onClick={onClick}>
      {children}
    </Theme>
  )
}

EventLinkButton.propTypes = {
  children: PropTypes.node,
  isInline: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.string,
}

export const ExternalLinkButton = ({ children, theme, url }) => {
  const Theme = getThemedComponent(theme)

  return (
    <Theme href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </Theme>
  )
}

ExternalLinkButton.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export const InternalLinkButton = ({ children, path, theme }) => {
  const Theme = getThemedComponent(theme)

  return (
    <Theme as={Link} to={path}>
      {children}
    </Theme>
  )
}

InternalLinkButton.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  theme: PropTypes.string,
}
