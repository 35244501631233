import { Banner } from "../vendor/hoa/banner"
import { Grid } from "../vendor/hoa/grid"
import { ImageByText } from "../vendor/hoa/image_by_text"
import { ImageMarquee } from "../vendor/hoa/image_marquee"
import { KlaviyoForm } from "../vendor/hoa/klaviyo"
import { MuxVideoPlayer } from "../vendor/hoa/mux_video"
import { YouTubePlayer } from "../vendor/hoa/you_tube_player"

import { Hero } from "../components/hero/hero"
import AnchorNav from "../components/pageComponents/AnchorNavPageComponent"
import Bio from "../components/pageComponents/BioPageComponent"
import FAQ from "../components/pageComponents/FAQPageComponent"
import FeaturedText from "../components/pageComponents/FeaturedTextPageComponent"
import ImageByTextPortrait from "../components/pageComponents/ImageByTextPortraitPageComponent"
import ItemRow from "../components/pageComponents/ItemRowPageComponent"
import Menu from "../components/pageComponents/MenuPageComponent"
import RichText from "../components/pageComponents/RichTextPageComponent"
import SubNav from "../components/pageComponents/SubNavPageComponent"
import Calendly from "../components/calendly/Calendly"
import { Section } from "../components/section/section"
import { Grid as PureGrid } from "../components/grid/grid"
import { GridCell } from "../components/grid/grid-cell"
import { Text } from "../components/text/text"
import { Image } from "../components/img/img"
import { InternalButton, ExternalButton } from "../components/buttons/buttons"
import { Carousel } from "../components/carousel/carousel"
import { HorizontalContent } from "../components/horizontal-content/horizontal-content"
import { Testimonial } from "../components/testimonial/testimonial"
import { MindBody } from "../components/maindbody/mindbody"
import { Typeform } from "../components/typeform/typeform"

const pageComponents = {
  AnchorNav,
  Banner,
  Bio,
  Calendly,
  FAQ,
  FeaturedText,
  Grid,
  ImageByText,
  ImageByTextPortrait,
  ImageMarquee,
  ItemRow,
  KlaviyoForm,
  Menu,
  RichText,
  SubNav,
  VideoPlayer: MuxVideoPlayer,
  YouTubePlayer,

  // New components
  Hero,
  Section,
  Carousel,
}

const components = {
  PureGrid,
  GridCell,
  Text,
  Image,
  InternalButton,
  ExternalButton,
  HorizontalContent,
  Testimonial,
  MindBody,
  Typeform,
}

const compToReact =
  components =>
  ({ _type }) => {
    const Component = components[_type]

    if (!Component) {
      throw new Error(`No component registered for _type == ${_type}`)
    }

    return Component
  }

export const componentToReact = compToReact(components)

export const pageComponentToReact = compToReact(pageComponents)
