import React, { useContext } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import CartPadding from "./CartPadding"
import CMSContext from "../context/CMSContext"
import StoreContext from "../context/StoreContext"
import { observer } from "mobx-react-lite"

const Copy = styled.div`
  margin: 25px 0 16px;
`

const StyledLink = styled(Link)`
  font-size: 0.9375rem;
  text-decoration: underline;
`

const EmptyCart = () => {
  const {
    siteSettings: { emptyCartLink },
  } = useContext(CMSContext)

  const { overlayStore } = useContext(StoreContext)

  return (
    <CartPadding>
      <Copy>Your cart is currently empty.</Copy>
      <StyledLink
        onClick={() => {
          overlayStore.hide()
        }}
        to={emptyCartLink.path.current}
      >
        Browse All
      </StyledLink>
    </CartPadding>
  )
}

EmptyCart.propTypes = {}

export default observer(EmptyCart)
