import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import CartHeader from "./CartHeader"
import CartItems from "./CartItems"
import EmptyCart from "./EmptyCart"
import FreeShippingProgress from "./FreeShippingProgress"
import StoreContext from "../context/StoreContext"
import styled from "@emotion/styled"
import VerticalScrollContainer from "../VerticalScrollContainer"
import CartTotals from "./CartTotals"
import Button from "../Button"
import CartPadding from "./CartPadding"
import Spinner from "../Spinner"

const CartLayout = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;

  @media (max-width: ${props => props.theme.phoneMax}) {
    padding-bottom: 55px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    padding-bottom: 20px;
  }
`

const Notes = styled.div`
  font-size: 0.75rem;
  margin: 14px 0 22px;
  text-align: center;
`

const Cart = () => {
  const { checkoutStore } = useContext(StoreContext)
  const { checkout, isCheckingOut, isLoading, itemCount, subtotal } =
    checkoutStore

  if (isLoading) {
    return (
      <CartLayout>
        <CartHeader />
        <Spinner />
      </CartLayout>
    )
  } else if (itemCount > 0) {
    return (
      <CartLayout>
        <div>
          <CartHeader />
          <FreeShippingProgress checkout={checkout} />
        </div>

        <VerticalScrollContainer>
          <CartItems
            checkout={checkout}
            onRemoveItem={item => {
              checkoutStore.removeLineItems(item.id)
            }}
            onUpdateItem={({ id }, updates) => {
              checkoutStore.updateLineItems([{ id, ...updates }])
            }}
          />
        </VerticalScrollContainer>

        <div>
          <CartTotals subtotal={subtotal} />

          <CartPadding>
            <Button
              disabled={isCheckingOut}
              onClick={() => {
                checkoutStore.navigateToCheckout()
              }}
            >
              {isCheckingOut ? "Loading..." : "Checkout"}
            </Button>

            <Notes>
              Shipping, taxes, and discounts calculated at checkout.
            </Notes>
          </CartPadding>
        </div>
      </CartLayout>
    )
  } else {
    return (
      <>
        <CartHeader />
        <EmptyCart />
      </>
    )
  }
}

Cart.propTypes = {}

export default observer(Cart)
