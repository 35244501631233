import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"

const TEXT_VARIANT = {
  subject: "text-xs lg:text-base uppercase font-bold",
  title1: "text-4xl lg:text-6xl leading-tight font-light",
  title2: "text-3xl lg:text-5xl leading-tight font-light",
  description: "text-lg font-light",
  medium: "font-medium",
  small: "text-sm font-light",
}

const TEXT_COLOR = {
  clay: "text-clay",
  sand: "text-sand",
}

const MARGIN_TOP_MAP = {
  16: "mt-4",
  24: "mt-6",
  32: "mt-8",
}

const MARGIN_BOTTOM_MAP = {
  16: "mb-4",
  24: "mb-6",
  32: "mb-8",
}

const TEXT_ALIGN = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
}

const getTextVariant = variant => {
  return TEXT_VARIANT[variant] || ""
}

const getMargin = (value, dir) => {
  if (dir === "top") return MARGIN_TOP_MAP[value] || ""
  return MARGIN_BOTTOM_MAP[value] || ""
}

const getTextAlign = value => {
  return TEXT_ALIGN[value] || ""
}

const getTextColor = value => {
  return TEXT_COLOR[value] || ""
}

export const Text = ({
  className,
  text,
  variant,
  marginTop,
  marginBottom,
  align,
  color,
}) => {
  const cn = clsx(
    getTextVariant(variant),
    getMargin(marginTop, "top"),
    getMargin(marginBottom, "bottom"),
    getTextAlign(align),
    getTextColor(color),
    className
  )
  return <p className={cn}>{text}</p>
}

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  align: PropTypes.string,
  color: PropTypes.string,
}
