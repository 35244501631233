import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import { convertNavItem } from "../../lib/navigation"
import StoreContext from "../context/StoreContext"
import { MobileNavContainer } from "../../vendor/hoa/nav"
import CMSContext from "../context/CMSContext"

const MobileNav = () => {
  const { navigation } = useContext(CMSContext)
  const { overlayStore } = useContext(StoreContext)
  const { isMobileNavExpanded } = overlayStore

  const hide = () => {
    overlayStore.hide()
  }

  return (
    <MobileNavContainer
      convertNavItem={convertNavItem}
      isExpanded={isMobileNavExpanded}
      navigation={navigation}
      onHide={hide}
    />
  )
}

MobileNav.propTypes = {}

export default observer(MobileNav)
