import clsx from "clsx"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const BUTTON_STYLES = "h-12 px-6 rounded-full text-sm font-medium"

const THEME = {
  light: "bg-clay",
  dark: "bg-onyx",
}

const getFullWidth = ({ mobile, tablet, desktop } = {}) => {
  return clsx(
    mobile ? "w-full" : "w-auto",
    tablet ? "md:w-full" : "md:w-auto",
    desktop ? "lg:w-full" : "lg:w-auto"
  )
}

export const InternalButton = ({ text, url, theme, fullWidth }) => {
  return (
    <Link to={url.path.current}>
      <button
        className={clsx(BUTTON_STYLES, THEME[theme], getFullWidth(fullWidth))}
      >
        {text}
      </button>
    </Link>
  )
}

InternalButton.propTypes = {
  text: PropTypes.string,
  url: PropTypes.object.isRequired,
  theme: PropTypes.string,
  fullWidth: PropTypes.object,
}

export const ExternalButton = ({ text, url, theme, fullWidth }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <button
        className={clsx(BUTTON_STYLES, THEME[theme], getFullWidth(fullWidth))}
      >
        {text}
      </button>
    </a>
  )
}

ExternalButton.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.string,
  fullWidth: PropTypes.object,
}
