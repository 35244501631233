import React from "react"
import styled from "@emotion/styled"
import { MaxWidthContext } from "../vendor/hoa/use_full_width_sizes"

const maxWidth = 1280

const Container = styled(({ className, children }) => (
  <MaxWidthContext.Provider value={maxWidth}>
    <div className={className}>{children}</div>
  </MaxWidthContext.Provider>
))`
  margin: 0 auto;
  max-width: min(100vw, ${maxWidth}px);
  padding: 0 var(--default-margin);
`

export default Container

const narrowMaxWidth = 900

export const NarrowContainer = styled(({ className, children }) => (
  <MaxWidthContext.Provider value={narrowMaxWidth}>
    <div className={className}>{children}</div>
  </MaxWidthContext.Provider>
))`
  margin: 0 auto;
  max-width: min(100vw, ${narrowMaxWidth}px);
  padding: 0 calc(2 * var(--default-margin));
`
