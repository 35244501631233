import React from "react"

export const LogoFullIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 292 16"
    className="block"
  >
    <g fill="white">
      <path d="M11.963 15.195h-1.597v-6.68H1.597v6.68H0V.805h1.597V6.83h8.76V.805h1.598v14.39h.008ZM29.185.5c4.18 0 7.324 3.452 7.324 7.504 0 4.053-3.143 7.505-7.324 7.505-4.18 0-7.324-3.452-7.324-7.505C21.861 3.952 24.98.5 29.185.5Zm0 13.314c3.271 0 5.727-2.627 5.727-5.81 0-3.182-2.456-5.81-5.727-5.81-3.27 0-5.726 2.628-5.726 5.81 0 3.183 2.455 5.81 5.726 5.81ZM58.329 9.61c0 3.684-1.835 5.89-6.016 5.89-4.18 0-6.015-2.206-6.015-5.89V.804h1.597v8.804c0 3.049 1.53 4.196 4.418 4.196 2.89 0 4.419-1.147 4.419-4.196V.805h1.597v8.804ZM75.458 6.758c2.54 0 5.123.978 5.123 4.16 0 3.183-3.16 4.582-6.508 4.582-4.146 0-6.015-2.188-6.814-3.084l1.147-1.193c.832.79 2.2 2.591 5.726 2.591 3.076 0 4.852-1.228 4.852-2.896 0-1.802-1.725-2.474-3.093-2.474H73.02c-2.634 0-4.766-.924-4.766-3.604C68.253 2.85 69.96.5 74.14.5c3.127 0 5.021 1.569 5.71 2.107l-1.003 1.327c-.977-.69-2.285-1.748-4.741-1.748-3.076 0-4.257 1.515-4.257 2.645 0 1.918 2.311 1.918 3.943 1.918h1.656l.009.01ZM101.491 15.195H89.894V.805h11.198V2.49h-9.601v4.394h5.055v1.685h-5.055v4.932h10v1.695ZM172.326 15.195h-1.784l-2.218-4.931h-6.542l-2.141 4.931h-1.785l6.492-14.39h1.597l6.381 14.39Zm-7.18-12.337-2.565 5.72h5.038l-2.473-5.72ZM184.349 15.195h-1.598V2.5h-4.979V.805h11.564V2.49h-4.979v12.696l-.008.01ZM210.433 15.195h-1.597v-6.68h-8.76v6.68h-1.597V.805h1.597V6.83h8.76V.805h1.597v14.39ZM231.318 15.195h-10.162V.805h1.598V13.5h8.564v1.695ZM251.548 15.195H239.95V.805h11.199V2.49h-9.601v4.394h5.055v1.685h-5.055v4.932h10v1.695ZM266.366 15.195h-1.597V2.5h-4.979V.805h11.564V2.49h-4.979v12.696l-.009.01ZM292 15.195h-11.598V.805h11.199V2.49H282v4.394h5.055v1.685H282v4.932h10v1.695ZM119.759 8.013c0-2.528 1.903-4.205 4.095-4.205 2.192 0 4.095 1.668 4.095 4.205 0 2.538-1.903 4.197-4.095 4.197-2.192 0-4.095-1.668-4.095-4.197Zm7.086 0c0-1.667-1.266-3.039-2.991-3.039s-2.991 1.372-2.991 3.04c0 1.667 1.266 3.039 2.991 3.039s2.991-1.372 2.991-3.04ZM134.441 12.048h-1.096v-8.06h6.253v1.156h-5.149V7.26h3.127v1.157h-3.127v3.622l-.008.01Z" />
    </g>
  </svg>
)
