import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { pageComponentToReact } from "../lib/pageComponentToReact"
import Container, { NarrowContainer } from "./Container"
import { ImgLoadingContext } from "../vendor/hoa/sanity_image"

const FullWidth = styled.div``

const getHorizontalContainer = horizontalMargins => {
  switch (horizontalMargins || "") {
    case "fullWidth":
      return FullWidth

    case "narrow":
      return NarrowContainer

    default:
      return Container
  }
}

const VerticalContainer = styled.div`
  background-color: ${({ background }) =>
    background === "gray" ? "#3D3935" : "transparent"};

  margin: var(--vertical-container-margin) 0;
  padding: ${({ background }) =>
      background === "gray" ? "var(--default-margin)" : 0}
    0;

  &:first-of-type {
    margin-top: 0;
  }
`

const DefaultVerticalContainer = styled(VerticalContainer)`
  --vertical-container-margin: var(--default-margin);
`

const ExtraMarginVerticalContainer = styled(VerticalContainer)`
  --vertical-container-margin: var(--extra-margin);
`

const GridGapVerticalContainer = styled(VerticalContainer)`
  --vertical-container-margin: var(--grid-gap);
`

const getVerticalContainer = verticalMargins => {
  switch (verticalMargins) {
    case "extraMargin":
      return ExtraMarginVerticalContainer

    case "gridGap":
      return GridGapVerticalContainer

    case "noMargin":
      return VerticalContainer

    default:
      return DefaultVerticalContainer
  }
}

const renderComponent = pageComponent => {
  const {
    _key,
    background,
    containerId,
    horizontalMargins,
    verticalMargins,
    _type,
  } = pageComponent

  const HorizontalContainer = getHorizontalContainer(horizontalMargins)
  const VerticalContainer = getVerticalContainer(verticalMargins)
  const Component = pageComponentToReact(pageComponent)

  if (["Hero", "Section", "Carousel"].includes(_type)) {
    return <Component key={_key} {...pageComponent} />
  }

  return (
    <VerticalContainer key={_key} background={background} id={containerId}>
      <HorizontalContainer>
        <Component {...pageComponent} />
      </HorizontalContainer>
    </VerticalContainer>
  )
}

const PageComponents = ({ components }) => {
  const [first, ...rest] = components

  return (
    <>
      <ImgLoadingContext.Provider value="eager">
        {first && renderComponent(first)}
      </ImgLoadingContext.Provider>

      {rest.map(component => renderComponent(component))}
    </>
  )
}

PageComponents.propTypes = {
  components: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PageComponents
