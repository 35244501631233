import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Link } from "gatsby"
import { observer } from "mobx-react-lite"

import { CMSLink, CMSLinkConfigProvider } from "../../vendor/hoa/links"
import CMSContext from "../context/CMSContext"

import internalLinkPath from "../../lib/internalLinkPath"

import CartButton from "./cart-button"
import MenuButton from "./menu-button"
import { LogoFullIcon } from "../../icons/logo-full"

const renderItem = (item, { product }) => {
  const linkClassName = "uppercase text-xs "
  return (
    // See https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/ for explanation
    // of Gatsby Link props
    <CMSLinkConfigProvider
      key={item._key}
      getInternalLink={document => [
        Link,
        {
          to: internalLinkPath(document),
          activeClassName: "active-link",
          className:
            linkClassName +
            (item.productTypes?.some(t => t === product?.productType)
              ? "active-link"
              : null),
          partiallyActive: true,
        },
      ]}
    >
      <CMSLink link={item} className={linkClassName}>
        {item.name}
      </CMSLink>
    </CMSLinkConfigProvider>
  )
}
const TopNav = ({ product }) => {
  const { navigation } = useContext(CMSContext)

  return (
    <header className="fixed h-[60px] inset-x-4 md:inset-x-10 mt-10 bg-onyx/60 rounded-lg grid grid-cols-headerMobile lg:grid-cols-header items-center z-30 px-2 md:px-6 backdrop-blur-sm text-white">
      <MenuButton />

      <Link to="/" className="w-[220px] md:w-[292px] h-auto">
        <LogoFullIcon />
      </Link>

      <div className="flex justify-end">
        <nav className="hidden lg:grid grid-cols-[repeat(5,_auto)] mr-8 gap-x-8 items-center">
          {navigation.desktopRightItems?.map(item =>
            renderItem(item, { product })
          )}
        </nav>
        <CartButton />
      </div>
    </header>
  )
}

TopNav.propTypes = {
  product: PropTypes.object,
}

export default observer(TopNav)
