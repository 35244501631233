import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import { componentToReact } from "../../lib/pageComponentToReact"

const THEME = {
  clay: "bg-clay",
  stone: "bg-stone",
  card: "bg-card",
}

const getTheme = value => {
  return THEME[value] || ""
}

export const Section = ({ className, content, backgroundColor }) => {
  return (
    <section
      className={clsx("max-sm:px-6", className, getTheme(backgroundColor))}
    >
      {(content || []).map(component => {
        const Component = componentToReact(component)
        return <Component key={component._key} {...component} />
      })}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array,
  backgroundColor: PropTypes.string,
}
