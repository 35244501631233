import clsx from "clsx"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"

import { Image } from "../img/img"
import { Rear } from "./carousel-item-rear"
import { Legend } from "./carousel-item-legend"
import { SwiperButtons } from "./carousel-buttons"

import "swiper/swiper-bundle.min.css"

const Card = ({ front, rear }) => {
  if (!front && !rear) return null

  const className = clsx(
    { "rounded-2xl": front.rounded },
    "overflow-hidden",
    "relative"
  )

  return (
    <div className={className}>
      <Image image={front.image} />
      <Rear {...rear} />
    </div>
  )
}

Card.propTypes = {
  front: PropTypes.object,
  rear: PropTypes.object,
}

export const Carousel = ({ carousel }) => {
  const [edgeState, setEdgeState] = useState({
    isBeginning: true,
    isEnd: false,
  })

  if (!carousel) return null
  const { items, title } = carousel

  return (
    <section className="md:px-10 py-20">
      {title && (
        <p className="text-3xl lg:text-5xl leading-tight font-light mb-8 px-6 md:px-0">
          {title}
        </p>
      )}
      <Swiper
        spaceBetween={32}
        slidesPerView={5}
        freeMode={true}
        modules={[FreeMode]}
        className="!px-16 !md:px-0"
        onSlideChange={({ isBeginning, isEnd }) => {
          setEdgeState({ isBeginning, isEnd })
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
          1440: {
            slidesPerView: 5,
          },
          1730: {
            slidesPerView: 6,
          },
        }}
      >
        {items.map(item => (
          <SwiperSlide key={item._key}>
            <Card {...item} />
            <Legend {...item} />
          </SwiperSlide>
        ))}
        <SwiperButtons {...edgeState} />
      </Swiper>
    </section>
  )
}

Carousel.propTypes = {
  carousel: PropTypes.object,
}
