import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import clsx from "clsx"

export const Title = ({ children, className }) => (
  <div className={clsx("hoa-title", className)}>{children}</div>
)

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export const Heading = styled(Title)`
  margin-bottom: 25px;
  text-align: center;
`
