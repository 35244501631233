import React from "react"
import PropTypes from "prop-types"

import { componentToReact } from "../../lib/pageComponentToReact"

export const Legend = ({ legend }) => {
  if (!legend?.content) return null

  return (
    <div className="mt-8">
      {legend.content.map(component => {
        const Component = componentToReact(component)
        return <Component key={component._key} {...component} />
      })}
    </div>
  )
}

Legend.propTypes = {
  legend: PropTypes.object,
}
