import { observer } from "mobx-react-lite"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"
import { getCartItemAttribute } from "../../lib/shopify/cartUtil"
import { toLegacyId } from "../../lib/shopify/shopifyUtil"
import StoreContext from "../context/StoreContext"

const Price = styled.div`
  font-size: 0.8125rem;
  line-height: 1.5;
`

const CompareAtPrice = styled(Price)`
  color: var(--color-disabled);
  text-decoration: line-through;
`

const CartItemPricing = ({ item }) => {
  const { productStore } = useContext(StoreContext)
  const productId = toLegacyId(item.variant.product.id)

  const formattedPriceV2 = ({ amount }) =>
    formatCurrency(
      productStore.getPrice(
        item.quantity * amount,
        productId,
        getCartItemAttribute(item, "rechargeType")
      )
    )

  return (
    <div>
      {item.variant.compareAtPriceV2 && (
        <CompareAtPrice>
          {formattedPriceV2(item.variant.compareAtPriceV2)}
        </CompareAtPrice>
      )}

      <Price>{formattedPriceV2(item.variant.priceV2)}</Price>
    </div>
  )
}

CartItemPricing.propTypes = {
  item: PropTypes.object.isRequired,
}

export default observer(CartItemPricing)
