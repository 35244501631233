import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { convertNavItem } from "../lib/navigation"
import CMSContext from "./context/CMSContext"
import { FooterNav } from "../vendor/hoa/nav/FooterNav"

const Footer = () => {
  const {
    site: {
      siteMetadata: { year },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          year
        }
      }
    }
  `)

  const {
    navigation: { footerItems, legalItems },
  } = useContext(CMSContext)

  return (
    <FooterNav
      copyrightMessage={`© ${year} House of Athlete. All Rights Reserved.`}
      footerItems={footerItems.map(item =>
        convertNavItem(item, { type: "footerNestedNavItem" })
      )}
      legalItems={legalItems}
    />
  )
}

Footer.propTypes = {}

export default Footer
