import { omit } from "lodash"

/**
 * Converts a `collectionNavItem` to a `nestedNavItem` or `footerNestedNavItem`.
 */
const collectionItemToNested = (item, { type = "nestedNavItem" } = {}) => {
  switch (type) {
    case "nestedNavItem":
      return {
        _key: item._key,
        _type: "nestedNavItem",
        name: item.name,
        items: [
          { ...item.collection.collectionItem, _key: `nested-${item._key}` },
          ...item.collection.subCollectionItems,
        ],
      }

    case "footerNestedNavItem":
      return {
        _key: item._key,
        _type: "footerNestedNavItem",
        name: item.name,
        items: [...item.collection.subCollectionItems],
        headingLink: {
          ...omit(item.collection.collectionItem, ["name"]),
          _key: `nested-${item._key}`,
          _type: "internalLink",
        },
      }

    default:
      throw new Error(`unknown type: ${JSON.stringify(type)}`)
  }
}

export const convertNavItem = (item, opts = {}) => {
  switch (item._type) {
    case "collectionNavItem":
      return collectionItemToNested(item, opts)

    default:
      return item
  }
}
