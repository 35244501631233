import PropTypes from "prop-types"
import React from "react"

export const TopNavButton = ({ children, className, onClick }) => (
  <button
    onClick={onClick}
    className={
      "flex items-center justify-center relative w-10 h-10 rounded-full bg-onyx border-0 p-0 cursor-pointer text-white " +
      className
    }
  >
    {children}
  </button>
)

TopNavButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
