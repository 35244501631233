import PropTypes from "prop-types"
import React from "react"
import { ImageOrVideo } from "./image-or-video"

export const Hero = ({
  subject,
  title,
  description,
  scrollNotice,
  imageOrVideo,
}) => {
  return (
    <section className="relative h-full text-white">
      {imageOrVideo && <ImageOrVideo fullHeight {...imageOrVideo} />}
      <div className="absolute bottom-0 left-0 w-full py-20 lg:py-[120px] px-6 lg:px-10 grid grid-cols-1 lg:grid-cols-[1fr,_auto] z-10 bg-gradient-to-t from-onyx">
        <div className="max-w-5xl">
          <p className="text-xs lg:text-base uppercase font-bold">{subject}</p>
          <h1 className="text-4xl lg:text-6xl leading-tight font-light my-2 lg:my-4">
            {title}
          </h1>
          <p className="text-xl lg:text-4xl leading-tight">{description}</p>
        </div>
        <div className="hidden lg:block relative w-[10rem]">
          <p className="rotate-90 uppercase text-xs font-semibold shrink-0 absolute top-1/2 -right-1/3">
            {scrollNotice ? "SCROLL to expLore" : ""}
          </p>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  subject: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  scrollNotice: PropTypes.bool,
  imageOrVideo: PropTypes.object,
}
