import React, { useContext } from "react"
import { observer } from "mobx-react-lite"
import StoreContext from "../context/StoreContext"
import styled from "@emotion/styled"
import MindBodyModal from "./MindBodyModal"
import { ModalBackground } from "../../vendor/hoa/modal"
import { CloseButton } from "../../vendor/hoa/close_button"
import BackInStockModal from "./BackInStockModal"
import ShopPayInstallmentsModal from "./ShopPayInstallmentsModal"
import { PreventScrolling } from "../../vendor/hoa/prevent_scrolling"

const Styled = styled.div`
  --modal-container-padding: ${({ padding }) => padding};

  background: white;
  left: 50%;
  overflow: hidden;
  padding: var(--modal-container-padding);
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`

const StyledCloseButton = styled(CloseButton)`
  padding: 6px 8px;
  position: absolute;
  right: 17px;
  top: 19px;
  z-index: 10000;
  width: 17px;
`

const getModalComponent = name => {
  switch (name) {
    case "backInStock":
      return BackInStockModal

    case "mindbody":
      return MindBodyModal

    case "shopPayInstallments":
      return ShopPayInstallmentsModal

    default:
      return null
  }
}

const ModalContainer = () => {
  const { modalStore } = useContext(StoreContext)
  const ModalComponent = getModalComponent(modalStore.activeModal)

  const close = () => {
    modalStore.hideModal()
  }

  return (
    <>
      <ModalBackground isExpanded={!!ModalComponent} onClick={close} />

      {ModalComponent && (
        <>
          <PreventScrolling />

          <Styled padding={ModalComponent.padding || "25px"}>
            <StyledCloseButton alt="close dialog" onClick={close} />
            <ModalComponent {...modalStore.modalData} onClose={close} />
          </Styled>
        </>
      )}
    </>
  )
}

ModalContainer.propTypes = {}

export default observer(ModalContainer)
