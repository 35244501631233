import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import { useSwiper } from "swiper/react"

import { ArrowLeft, ArrowRight } from "../../icons/index"

const Button = ({ onClick, className, children, disabled }) => {
  const buttonClassName = clsx(
    "w-12 h-12 rounded-full bg-clay flex justify-center items-center",
    className
  )
  return (
    <button onClick={onClick} className={buttonClassName} disabled={disabled}>
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

export const SwiperButtons = ({ isBeginning, isEnd }) => {
  const swiper = useSwiper()

  return (
    <div className="-mr-10 md:mr-0 mt-10 flex justify-end">
      <Button
        className={`mr-4 ${isBeginning ? "opacity-40" : "opacity-100"}`}
        disabled={isBeginning}
        onClick={() => swiper.slidePrev()}
      >
        <ArrowLeft className="block w-4 h-4" />
      </Button>
      <Button
        className={isEnd ? "opacity-40" : "opacity-100"}
        disabled={isEnd}
        onClick={() => swiper.slideNext()}
      >
        <ArrowRight className="block w-4 h-4" />
      </Button>
    </div>
  )
}

SwiperButtons.propTypes = {
  isBeginning: PropTypes.bool,
  isEnd: PropTypes.bool,
}
