import { memoize } from "lodash"

const loadScript = uri => {
  return new Promise(resolve => {
    const scriptTag = document.createElement("script")
    scriptTag.src = uri
    scriptTag.async = true
    scriptTag.onload = () => {
      resolve()
    }
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(scriptTag, firstScript)
  })
}

export default memoize(loadScript)
