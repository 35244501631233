import PropTypes from "prop-types"
import React from "react"

export const MenuIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.5 12.002h13M1.5 8.002h13M1.5 4.002h13"
    />
  </svg>
)

MenuIcon.propTypes = {
  className: PropTypes.string,
}
