import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import formatCurrency from "../../lib/formatCurrency"
import { Title } from "../Headings"
import { StyledRichText } from "../../vendor/hoa/rich_text"

const StyledItem = styled.div`
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: 25px;
  padding-bottom: 30px;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-gray);
  }
`

const ItemTitle = styled(Title)`
  margin: 0 0 15px;
`

const Price = styled.div`
  color: var(--color-brand-secondary);
  font-size: 1.3125rem;
`

const MenuItem = ({ description, price, title }) => (
  <StyledItem>
    <div>
      <ItemTitle>{title}</ItemTitle>
      {description && <StyledRichText blocks={description} />}
    </div>

    <Price>{formatCurrency(price)}</Price>
  </StyledItem>
)

MenuItem.propTypes = {
  description: PropTypes.arrayOf(PropTypes.object),
  price: PropTypes.number,
  title: PropTypes.string.isRequired,
}

const MenuTitle = styled(Title)`
  font-size: 1.75rem;
  margin: 30px 0;
`

const Divider = styled.div`
  background-color: var(--color-brand-secondary);
  height: 2px;
  margin: 10px auto 40px;
  max-width: 400px;
  width: 90%;
`

const MenuPageComponent = ({ items, title }) => (
  <>
    <MenuTitle>{title}</MenuTitle>

    <div>
      {items.map(item => (
        <MenuItem key={item._key} {...item} />
      ))}
    </div>

    <Divider />
  </>
)

MenuPageComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default MenuPageComponent
