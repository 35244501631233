import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import PreserveScrollLink from "../links/PreserveScrollLink"
import { isFunction, isNumber, sortBy, trimEnd } from "lodash"
import { EventLink } from "../../vendor/hoa/links"

export const SubNavContainer = styled.div`
  font-size: 0.8125rem;
`

export const SubNavItems = styled.div`
  column-gap: 1.7em;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  margin-bottom: 17px;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;

  a {
    padding-bottom: 5px;

    &.active-link {
      border-bottom: 1px solid var(--color-default-text);
      font-weight: var(--weight-slight-bold);
    }
  }
`

export const SubNavHeading = styled.h1`
  color: var(--color-disabled);
  font-weight: var(--weight-slight-bold);
  margin-bottom: 8px;
`

const SubNav = ({ heading, items }) => {
  const [clickedIndex, setClickedIndex] = useState()

  const getProps = ({ href, location: { pathname } }) => {
    if (!href || !pathname || isNumber(clickedIndex)) {
      return {}
    }

    pathname = trimEnd(pathname, "/")

    const activeItems = sortBy(
      items.filter(item => pathname.startsWith(item.to)),
      ({ to }) => -to.length
    )

    const activePath = activeItems[0]?.to

    if (href === activePath) {
      return { className: "active-link" }
    } else {
      return {}
    }
  }

  return (
    <SubNavContainer>
      <SubNavHeading>{heading}</SubNavHeading>

      <SubNavItems>
        {items.map((item, index) =>
          isFunction(item.onClick) ? (
            <EventLink
              key={index}
              className={clickedIndex === index ? "active-link" : null}
              onClick={() => {
                setClickedIndex(index)
                item.onClick()
              }}
            >
              {item.title}
            </EventLink>
          ) : (
            <PreserveScrollLink key={index} getProps={getProps} to={item.to}>
              {item.title}
            </PreserveScrollLink>
          )
        )}
      </SubNavItems>
    </SubNavContainer>
  )
}

SubNav.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SubNav
